import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  const [currentTime, setCurrentTime] = useState(0);
  
  useEffect (() => {
    // fetch('/api/time').then(res=>res.json()).then(data => {
    //   setCurrentTime(data.time);
    // });
    console.log('hello world.')
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
         Welcome to Memorilia!
        </p>
        <p>The current time is {currentTime}</p>
      </header>
    </div>
  );
}

export default App;
